import { isBoolean, isNil, isNumber, isString, type Nil } from "@mcwd/typescript-type-guards";


export function parseBool(value: unknown, { parseFromNumber = false } = {}): boolean | null {
  if (isNil(value)) {
    return null;
  }
  if (isBoolean(value)) return value;
  if (isString(value)) {
    if (/^true|false$/i.test(value)) return /^true$/i.test(value);
    if (/^0|1$/.test(value)) {
      value = parseInt(value);
    }
  }
  if (parseFromNumber && isNumber(value) && (value === 0 || value === 1)) {
    return value === 1;
  }
  return null;
}


export function parseNumber(value: unknown, { parseFromBoolean = false } = {}): number | Nil {
  if (isNil(value) || isNumber(value)) {
    return value;
  }
  if (isString(value)) {
    const val = Number(value);
    if (!Number.isNaN(val)) {
      return val;
    }
    value = parseFromBoolean ? parseBool(value) : value;
  }
  if (parseFromBoolean && isBoolean(value)) {
    return Number(value);
  }
  return null;
}
export type RemoveEmptyProperties<T extends object> = Partial<{
  [K in keyof T] : T[K] extends null | undefined ? never : T[K] 
}>;
export function removeEmptyProperties<T extends object>(obj: T): RemoveEmptyProperties<T> {
  return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value != null && value !== '')
  ) as RemoveEmptyProperties<T>;
}