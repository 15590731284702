import type { LoggerCss } from "./LoggerCss.js";
import { addLoggerFilter, checkLoggerIsFiltered, getLoggerFilter, getLoggerFilterOverride, addLoggerFilterOverride, resetLoggerFilter, removeLoggerFilter, type TagsRecord } from "./LoggerTags.js";
import type { LoggerTags } from "./LoggerTagsType.js";
import type { ILogger } from "./ILogger.js";
import type { LoggerFnBindArgs } from "./LoggerBindingTypes.js";
import { getBoundConsoleFn, getLoggerPrefixArgs } from "./LoggerBinding.js";
import { isObject, isString } from "@mcwd/typescript-type-guards";
import { ConsoleLoggingLevels, BasicConsoleFnKeys, WindowConsole, type WindowConsoleFunctionKeys } from "./WindowConsole.js";
import { BasicButtonLabelStyle, getStyledMessageArgs } from "./LoggerStyles.js";

function getBoundLoggerFnOrNoop<TFnName extends WindowConsoleFunctionKeys>(logger: ILogger, fnName: TFnName, ...args: any[]) {
    if ((BasicConsoleFnKeys as readonly string[]).includes(fnName)) {
        const level = ConsoleLoggingLevels.includes(fnName as ConsoleLoggingLevels) ? fnName as ConsoleLoggingLevels : "info";
        logger = logger.withTags({ level, logFn: fnName });
    }
    else {
        logger = logger.withTags({ logFn: fnName });
    }
    
    if (logger.checkLoggerIsFiltered()) {
        return (() => {});
    }
    return getBoundConsoleFn(fnName, ...args);
}

function getBoundLoggerFnFromPrefixArgs<TFnName extends BasicConsoleFnKeys>(logger: ILogger, fnName: TFnName) {
    const level = (ConsoleLoggingLevels as readonly string[]).includes(fnName) ? fnName as ConsoleLoggingLevels : "info";
    return getBoundLoggerFnOrNoop(logger, fnName, ... getLoggerPrefixArgs(logger, level));
}

export class Logger implements ILogger {
    #prefixes: string[];
    #prefixStyles: LoggerCss[];
    #tags: LoggerTags;

    static #allUsedTags: TagsRecord<string[]> = {};
    
    static #addToUsedTags(tags: LoggerTags) {
        for (const k of Object.keys(tags)) {
            if (!(k in this.#allUsedTags)) {
                Logger.#allUsedTags[k] = [];
            }
            tags[k] = (isString(tags[k]) ? [tags[k]] : tags[k]) as string[];
            for (const item of tags[k]){
                if (Logger.#allUsedTags[k].includes(item) === false) {
                    Logger.#allUsedTags[k].push(item);
                }
            }
        }
    }

    static get allUsedTags() {
        return Logger.#allUsedTags;
    }

    constructor({ tags, prefixStrings, prefixStyles }: { tags: LoggerTags, prefixStrings?: string[], prefixStyles?: LoggerCss[] }) {
        this.#tags = { ...tags };
        this.#prefixes = prefixStrings ?? [];
        this.#prefixStyles = prefixStyles ?? [];
        Logger.#addToUsedTags(tags);
    }

    /** Used to toggle loggers on and off globally when any of the tag values matches what is enabled/disabled globally */
    get tags(): LoggerTags {
        return { ... this.#tags };
    }

    get prefixes() {
        return Object.freeze([... this.#prefixes]);
    }

    get prefixStyles() {
        return Object.freeze([... this.#prefixStyles]);
    }

    static getFilters(): TagsRecord<string | string[] | null> {
        return getLoggerFilter();
    }

    static addFilters(filteredTags: TagsRecord<string | string[] | null>) {
        addLoggerFilter(filteredTags, true);
    }

    static removeFilters(tagsToRemove: string | TagsRecord<string | string[] | null>) {
        removeLoggerFilter(tagsToRemove);
    }

    /**
     * filtered tag overrides are used to remove specific tags from the logger filters when a wildcard is used
     */
    static getFilterOverrides(): TagsRecord<string[] | null> {
        return getLoggerFilterOverride();
    }

    /**
     * filtered tag overrides are used to remove specific tags from the logger filters when a wildcard is used
     */
    static setFilterOverrides(filteredTagsOverride: TagsRecord<string | string[] | null>) {
        addLoggerFilterOverride(filteredTagsOverride, true);
    }

    checkLoggerIsFiltered() {
        return checkLoggerIsFiltered(this);
    }

    static resetLoggerFilter() {
        resetLoggerFilter();
    }

    withTags(tags: Record<string, string>) {
        return new Logger({
            tags: { ...this.tags, ...tags },
            prefixStrings: [...this.prefixes],
            prefixStyles: [...this.prefixStyles]
        });
    }

    getChildLogger({ tags, prefixStrings, prefixStyles }: { tags?: Record<string, string>, prefixStrings?: string[], prefixStyles?: LoggerCss[] } = {}) {
        return new Logger({
            tags: { ...this.tags, ...(tags ?? {}) },
            prefixStrings: [...this.#prefixes, ... (prefixStrings ?? [])],
            prefixStyles: [...this.#prefixStyles, ...(prefixStyles ?? [])]
        });
    }

    #getFnWithBoundArgs<TFnName extends BasicConsoleFnKeys>(
        { fnName, style = "reset", formatString: formattingSuffix }: { fnName: TFnName } & LoggerFnBindArgs
    ): ILogger[TFnName] {
        const styles = [...this.#prefixStyles];
        if (isObject(style)) {
            styles.push(style);
        }
        // If style === 'reset': reset the log css (this is only needed if a format string is provided)
        else if (style === "reset" && isString(formattingSuffix) && formattingSuffix.trim() !== "") {
            // Add empty log style object which adds an empty string to the log parameters to set the css placeholder to nothing.
            styles.push({});
        }
        // All function names that don't match a log level, will log at the "info" level
        const level = ConsoleLoggingLevels.includes(fnName as ConsoleLoggingLevels)
            ? fnName as ConsoleLoggingLevels
            : "info";

        const logFnBoundArgs = getStyledMessageArgs({ level, messages: this.#prefixes, styles, formattingSuffix });
        return getBoundLoggerFnOrNoop(this, fnName, ...logFnBoundArgs);
    }

    bindDebugOptions(opts: LoggerFnBindArgs) { return this.#getFnWithBoundArgs({ fnName: "debug", ...opts }); }
    bindDirxmlOptions(opts: LoggerFnBindArgs) { return this.#getFnWithBoundArgs({ fnName: "dirxml", ...opts }); }
    bindErrorOptions(opts: LoggerFnBindArgs) { return this.#getFnWithBoundArgs({ fnName: "error", ...opts }); }
    bindGroupOptions(opts: LoggerFnBindArgs) { return this.#getFnWithBoundArgs({ fnName: "group", ...opts }); }
    bindGroupCollapsedOptions(opts: LoggerFnBindArgs) { return this.#getFnWithBoundArgs({ fnName: "groupCollapsed", ...opts }); }
    bindInfoOptions(opts: LoggerFnBindArgs) { return this.#getFnWithBoundArgs({ fnName: "info", ...opts }); }
    bindLogOptions(opts: LoggerFnBindArgs) { return this.#getFnWithBoundArgs({ fnName: "log", ...opts }); }
    bindTraceOptions(opts: LoggerFnBindArgs) { return this.#getFnWithBoundArgs({ fnName: "trace", ...opts }); }
    bindWarnOptions(opts: LoggerFnBindArgs) { return this.#getFnWithBoundArgs({ fnName: "warn", ...opts }); }

    get assert() { return getBoundLoggerFnOrNoop(this, "assert"); }
    get clear() { return getBoundLoggerFnOrNoop(this,"clear"); }
    get count() { return getBoundLoggerFnOrNoop(this,"count"); }
    get countReset() { return getBoundLoggerFnOrNoop(this,"countReset"); }
    get dir() { return getBoundLoggerFnOrNoop(this,"dir"); }
    get groupEnd() { return getBoundLoggerFnOrNoop(this,"groupEnd"); }
    get table() { return getBoundLoggerFnOrNoop(this,"table"); }
    get time() { return getBoundLoggerFnOrNoop(this,"time"); }
    get timeEnd() { return getBoundLoggerFnOrNoop(this,"timeEnd"); }
    get timeLog() { return getBoundLoggerFnOrNoop(this,"timeLog"); }
    get timeStamp() { return getBoundLoggerFnOrNoop(this,"timeStamp"); }

    get debug(): WindowConsole["debug"] { return getBoundLoggerFnFromPrefixArgs(this, "debug"); }
    get dirxml(): WindowConsole["dirxml"] { return getBoundLoggerFnFromPrefixArgs(this, "dirxml"); }
    get error(): WindowConsole["error"] { return getBoundLoggerFnFromPrefixArgs(this, "error"); }
    get group(): WindowConsole["group"] { return getBoundLoggerFnFromPrefixArgs(this, "group"); }
    get groupCollapsed(): WindowConsole["groupCollapsed"] { return getBoundLoggerFnFromPrefixArgs(this, "groupCollapsed"); }
    get info(): WindowConsole["info"] { return getBoundLoggerFnFromPrefixArgs(this, "info"); }
    get log(): WindowConsole["log"] { return getBoundLoggerFnFromPrefixArgs(this, "log"); }
    get trace(): WindowConsole["trace"] { return getBoundLoggerFnFromPrefixArgs(this, "trace"); }
    get warn(): WindowConsole["warn"] { return getBoundLoggerFnFromPrefixArgs(this, "warn"); }

    static #stylePresets = Object.freeze({
        ButtonLabel(styles?: LoggerCss) {
            return Object.freeze({
                ...BasicButtonLabelStyle,
                ...(styles ?? {})
            });
        }
    }) satisfies Record<string, (styles?: LoggerCss) => LoggerCss>;

    static get StylePresets() {
        return this.#stylePresets;
    }
}

addLoggerFilter({
    verboseDebug: ["*"],
    logFn: ["time", "timeEnd"]
}, false);


(window as any).McLogger = Logger;