import { getValueFromPropsPath } from "../nested-props.js";

export function convertArrayToPropIndexedObject (array, indexBy) {
  const propIndexedObj = array.reduce((result, item) => {
    const propValue = item[indexBy];
    return Object.assign(result, { [propValue]: item });
  }, {});
  return propIndexedObj;
}


export function convertArrayToDeepPropIndexedObject(array, indexByPath) {
  return array.reduce((result, item) => {
    const propValue = getValueFromPropsPath(item, indexByPath, []);
    if (Array.isArray(propValue)) {
      return Object.assign(result, propValue.reduce((innerResult, key) => {
        return Object.assign(innerResult, { [key]: item });
      }, {}));
    }
    else {
      return Object.assign(result, { [propValue]: item });
    }
  }, {});
}